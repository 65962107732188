$primary-color: #69C298;
$benefits-color: #3498DB;
$home-cta-color: #E74C3C;
$send-btn-color: #3aaa35;


.lp-bcg {
    height: 100vh;
    background-image: url('/images/bcg.jpg');
    overflow: visible;

    & h1 {
        color: white;
    }
}

.home-hl {
    font-size: 7rem;
    color: white;
    margin-top: 4rem;
    font-weight: bold;
}

.home-sub-hl {
    color: red;
    font-size: 3rem;
    color: red;
}

.home-text {
    color: white;
}

.home-cta {
    margin-top: 3rem;
    font-size: 3rem;
    background-color: $home-cta-color;
    border: none;
    color: #fff;
    text-decoration: none;
}

.lp-how-it-works {
    background-color: $primary-color;
}

.lp-benefits {
    background-color: $benefits-color;
}

.send-button {
    color: white;
    margin-top: 3rem;
    background-color: $send-btn-color;
    border: none;
}

.currency-marquee{
    color: #fff;
    margin-top:13px;
    white-space: nowrap;
    overflow: hidden;
}
.currency{
    display:inline-block;
    background-color: rgba(156, 157, 160, 0.13);
    padding:3px;
    margin-right:3px;
    border: 1px solid rgba(71, 72, 86, 0.5);
    border-radius: 3px;
    width: 200px;
    text-align: center;
}
.currency:hover{
    background-color: rgba(45, 48, 60, 0.32);
    cursor:pointer;
}
.currency-name{
    color: #69c298;
}
.currency-price{
    color:#a79595;
}


/*
	cookie-alert
*/
/* cookie banner */
#cookie-alert-wrapper {
	position: fixed;
	width: 100%;
	border: none;
	background-color: rgba(9,9,9,9.85);
	bottom: -145px;
	z-index: 7900;
	min-height: 60px;
	bottom: 0px;
	color: white;
	font-size: 16px;
	text-align: center;
}
#cookie-alert-wrapper .cookie-alert-acceptance {
	color: white;
	background: #60bf93;
	border: #44A87A 2px solid;
	border-radius: 2px;
	-moz-border-radius: 2px;
	padding: 2px 10px 2px 10px;
	cursor: pointer;
}
#cookie-alert-wrapper .cookie-alert-acceptance:hover {
background: #72c69f;
border-color: #44a87a;
}
#cookie-alert-wrapper .cookie-alert-close {
	background: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDc0IDc0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA3NCA3NDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0NDQ0NDQzt9DQoJLnN0MXtmaWxsOiNGRkZGRkY7fQ0KCS5zdDJ7ZmlsbDojNjY2NjY2O30NCjwvc3R5bGU+DQo8Zz4NCgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzcsNzEuNWMtOS42LDAtMTcuOC0zLjQtMjQuNC0xMC4xQzUuOCw1NC40LDIuNSw0Ni41LDIuNSwzN2MwLTkuNiwzLjQtMTcuOCwxMC4xLTI0LjQNCgkJQzE5LjIsNS45LDI3LjQsMi41LDM3LDIuNWM5LjYsMCwxNy44LDMuNCwyNC40LDEwLjFjNi44LDYuOSwxMC4xLDE0LjksMTAuMSwyNC40YzAsOS42LTMuNCwxNy44LTEwLjEsMjQuNA0KCQlDNTQuNCw2OC4yLDQ2LjUsNzEuNSwzNyw3MS41eiIvPg0KCTxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik0zNyw1YzguOSwwLDE2LjQsMy4xLDIyLjYsOS40QzY1LjgsMjAuNyw2OSwyOC4xLDY5LDM3cy0zLjEsMTYuNC05LjQsMjIuNkM1My4zLDY1LjgsNDUuOSw2OSwzNyw2OQ0KCQlzLTE2LjQtMy4xLTIyLjYtOS40UzUsNDUuOSw1LDM3czMuMS0xNi40LDkuNC0yMi42QzIwLjYsOC4xLDI4LjEsNSwzNyw1IE0zNywwQzI2LjgsMCwxOCwzLjcsMTAuOSwxMC45QzMuNywxOCwwLDI2LjgsMCwzNw0KCQljMCwxMCwzLjYsMTguOCwxMC44LDI2LjFDMTcuOSw3MC4zLDI2LjgsNzQsMzcsNzRjMTAsMCwxOC44LTMuNiwyNi4xLTEwLjhDNzAuMyw1Ni4xLDc0LDQ3LjIsNzQsMzdjMC0xMC0zLjYtMTguOC0xMC44LTI2LjENCgkJQzU2LjEsMy43LDQ3LjIsMCwzNywwTDM3LDB6Ii8+DQo8L2c+DQo8cGF0aCBjbGFzcz0ic3QyIiBkPSJNNTIuNCw0Ny43TDQxLjcsMzdsMTAuOS0xMC43YzAuNi0wLjYsMC42LTEuMSwwLTEuN2wtMy4xLTNjLTAuMy0wLjMtMC42LTAuNC0wLjktMC40Yy0wLjIsMC0wLjQsMC4xLTAuNywwLjQNCglMMzcsMzIuMUwyNi4xLDIxLjZjLTAuMy0wLjMtMC41LTAuNC0wLjctMC40Yy0wLjMsMC0wLjYsMC4xLTAuOSwwLjRsLTMsM2MtMC42LDAuNi0wLjYsMS4xLDAsMS43TDMyLjQsMzdMMjEuNiw0Ny45DQoJYy0wLjIsMC4xLTAuMywwLjMtMC4zLDAuN3MwLjEsMC43LDAuMywwLjlsMywzLjFjMC4yLDAuMiwwLjUsMC4zLDAuOSwwLjNzMC43LTAuMSwwLjktMC4zTDM3LDQxLjdsMTAuOSwxMC43DQoJYzAuMywwLjMsMC41LDAuNCwwLjcsMC40YzAuMywwLDAuNi0wLjEsMC45LTAuNGwzLTNjMC4zLTAuMiwwLjQtMC41LDAuNC0wLjlDNTIuOSw0OC4zLDUyLjcsNDgsNTIuNCw0Ny43eiIvPg0KPC9zdmc+");
	background-size: auto auto;
	background-size: 22px 22px;
	width: 22px;
	height: 22px;
	cursor: pointer;
}
@media (min-width: 950px) {
	/* cookie banner */
	#cookie-alert-wrapper .cookie-alert {
			top: 28%;
			float: left;
			left: 10px;
			position: absolute;
	}

	#cookie-alert-wrapper .cookie-alert-acceptance {
			top: 28%;
			float: right;
			right: 65px;
			position: absolute;
	}

	#cookie-alert-wrapper .cookie-alert-close {
			top: 30%;
			float: right;
			right: 10px;
			position: absolute;
	}
	/* cookie banner */
}

/* cookie banner */

.navbar-fixed-top .navbar-collapse {
	max-height: unset!important;
}
.navbar-nav {
	margin-left: 40px;
}
@media screen and (max-width: 767px) {
	.navbar-nav {
		margin-left: 5px;
	}	
}